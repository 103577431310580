import React from "react"
import NotFound from '../components/user/NotFound';
import Layout from '../templates/layout';

const NotFoundPage = () => (
  <Layout>
    <NotFound/>
  </Layout>
)

export const Head = () => <title>Nie znaleziono strony | Zapłatomat</title>;

export default NotFoundPage


